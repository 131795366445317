.aside-menu {
  padding: 1.5rem;
  border-radius: $border-radius-lg;
  
  .list-group-custom {
    .form-group {
      padding: .9rem 0;

      label {
        font-size: $font-size-sm;
      }
    }
  }
  .menu-nav {
    padding: 0;
    > .menu-section {
    padding: 0;
    margin: 0 0 10px 0;
    height: 20px;

      .menu-text {
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    > .menu-item {
      &.menu-item-active {
        > .menu-link {
          background-color: transparent!important;

          .menu-text {
            color: $gray-darker;
            font-size: $font-size-sm;
          }

          &:before {
            content: '';
            margin-right: 5px;
            border-left: 3px solid $primary!important;
          }         
        }
        &.menu-info {
          > .menu-link  {
            &:before {
                border-color: $info!important;
            }
          }
        }
        &.menu-success {
          > .menu-link  {
            &:before {
                border-color: $success!important;
            }
          }
        }
      }       
      > .menu-link {
        padding: 5px;
        min-height: 35px;     

        &:before {
          content: '';
          margin-right: 5px;
          border-left: 3px solid transparent !important;
        } 

        .menu-text {
          font-size: $font-size-sm;
          font-weight: 600;
          color: $gray-dark;
        }
      }   

      &.menu-item-open > .menu-link {
        background-color: transparent;      

        &.menu-item-active {
          &:before {
            content: '';
            margin-right: 5px;
            border-left: 3px solid $primary!important;
          }

          &.menu-info:before {
            border-color: $info!important;
          }

          &.menu-success:before {
            border-color: $success!important;
          } 

          .menu-text {
            color: $gray-darker;
          }
        }   

        .menu-text {
          color: $gray-dark
        }
      }
      
      .menu-submenu .menu-item {
        > .menu-heading,
        > .menu-link {           
          .menu-text {
            color: $gray-dark;
          }
        }
      }

      > .menu-submenu .menu-subnav > .menu-item {
        &.menu-item-active {
          > .menu-heading,
          > .menu-link {
            background-color: transparent!important;

            &:before {
              border-left: 3px solid $primary!important;
            }   

            .menu-text {
              color: $gray-darker;
            }
          } 
        }
        
        &.menu-info {
          > .menu-link  {
            &:before {
                border-color: $info!important;
            }
          }
        }

        &.menu-success {
          > .menu-link  {
            &:before {
                border-color: $success!important;
            }
          }
        }

        > .menu-link  {
          &:before {
            content: '';
            margin-right: 5px;
            border-left: 3px solid transparent;
          }

          .menu-text {
            font-weight: 600;
          }
        }
      }
    }
  }
}