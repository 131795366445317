.table {
  font-size: 14px;
  color: $table-color;
  font-weight: $font-weight-bolder;

  td {
    padding: 1.02rem .75rem;
  }

  tbody {
    border-bottom: 0;
  }
}