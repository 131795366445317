.form-control {
  border-color: $gray-200;
}

// custom select
select {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%; 
}

// custom input
.custom-input {
background: url("data:image/svg+xml;utf8,<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><rect id='bound' x='0' y='0' width='24' height='24'></rect><path d='M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z' id='Path-2' fill='%23b5b5c3' fill-rule='nonzero' opacity='0.3'></path><path d='M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z' id='Path' fill='%23b5b5c3' fill-rule='nonzero'></path></g></svg>") no-repeat scroll 7px 6px;
padding-left:35px;
}

th:first-child { 
  padding-left: 0
}

.auth-layout {
  label {
    font-weight: 600;
    font-size: 14px;
  }

  .form-control {
    height: 50px;
    border-color: $gray-100;
    background-color: $input-solid-bg;
  }

  .btn {
    padding: 1rem 1.42rem;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.inline-field {
  &.list-group {
    .form-group {
      padding: .65rem 0;
      border-bottom: 1px solid $secondary-light;
      margin-bottom: 0;

      &:last-child {
        border-bottom-width: 0;
      }
    }
  }

  &.sub-field {
    .form-group {
      padding-left: 1.25rem;
      
      &:first-child {
        border-top: 1px solid $secondary-light;
      }       
      &:last-child {
        border-bottom: 1px solid $secondary-light;
      }      
      label {
        color: $gray-light;
      }      
    }    
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      color: $black;
      margin-bottom: 0;
      font-weight: $font-weight-bold;
      .small {
        font-size: $small-font-size;
      }
    }

    .form-control {
      font-size: 12px;
      font-weight: $font-weight-bolder;
      height: calc(1em + .9rem + 2px);        
      width: 60px;
      color: $gray-light;
      text-align: center;
      background-color: $input-solid-bg;
      border-color: $input-solid-bg;
    }

    select.form-control {
      font-weight: $font-weight-normal;
      width: 105px;
      color: $gray-light;
      background-color: $white;
      padding: 0.1rem 1rem;
    }
  }
}