.multi-select {
  .select {
    &__multi-value {
      color: $gray-500;
      background-color: $body-bg;
    }

    &__multi-value__label {
      color: $gray-800;
    }    

    &__multi-value__remove {
      &:hover {
        background-color: $gray-200;
      }
    } 

    &__indicator-separator {
      display: none;
    }
    
    &__dropdown-indicator {
      display: none;
    }
  }

  .select__control { 
    border-color: $gray-200;
  }
}

.select {
  .select {
    &__control {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $input-solid-bg;
    }

    &__control--is-focused {
      border: 0;
      box-shadow: none;
      border-bottom: 1px solid $primary;

      &:hover {
        border: 0;
        border-bottom: 1px solid $primary;
      }
    }    

    &__value-container {
      margin-left: 25px;
    }

    &__indicators {
      position: absolute;
    }

    &__indicator-separator {
      display: none;
    }

    &__loading-indicator {
      padding: 16px 8px;
    }
  }
}