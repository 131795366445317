.dropzone-container {
  .symbol {
    > img {
      height: auto;
      min-height: 112px;
    }
  
    .icon-close {
      position: absolute;
      border-radius: 100%;
      top: -8px;
      right: -8px;
      background-color: $white;
    }
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: $gray-200;
    border-style: dashed;
    background-color: $gray-100;
    color: $gray-800;
    outline: none;
    transition: border .24s ease-in-out;

    &--active {
      border-color: $primary;
    }

    &--accept {
      border-color: $primary-alt;
    }

    &--reject {
      border-color: $danger;
    }
  }
}
