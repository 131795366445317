@import "react-quill/dist/quill.snow.css";
@import "react-toastify/dist/ReactToastify.css";

@import "./variables";

@import "./metronic/sass/style.react.scss";

@import "./global";

@import "components/card";
@import "components/forms";
@import "components/quill";
@import "components/tables";
@import "components/buttons";
@import "components/jumbotron";
@import "components/typography";
@import "components/multiSelect";
@import "components/menuVertical";
@import "components/menuVertical";
@import "components/dropzoneUpload";
@import "components/uniformsSubmit";
@import "components/loadingIndicator";
