
.btn,
.btn-link {
  font-weight: 600;
}

.auth-layout {
  .btn-primary {
    background-color: $primary-alt;

    &:hover,
    &:focus,
    &:active,
    &.focus,
    &.active {
      background-color: lighten($primary-alt, 10%);
    }
  }
}

.btn-close {
  position: relative;
  border-color: transparent;
  background-color: transparent;

  &:before {
    font-family: "Ki";
    content: "";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}