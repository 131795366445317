.quill {
  .ql-toolbar {
    border-color: $gray-200;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ql-container {
    border-color: $gray-200;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ql-editor {
    min-height: 200px;
    
    &.ql-blank::before {
      color: $gray-light;
      font-style: normal;
    }
  }
}