h1, h4, .h1, .h4 {
  font-weight: 600!important;
}

h1, .h1 {
  font-size: 24px!important;
}

h2, .h2 {
  font-size: 22px!important;
}

h3, .h3 {
  font-size: 18px!important;
}

h4, .h4 {
  font-size: 16px!important;
}