
//== Colors
//
//## Gray and brand colors for use across Bootstrap.

// Gray colors
$gray-100: 											#EEF1F5 !default;
$gray-200: 											#E5EAEE !default;
$gray-500: 											#B5B5C3 !default;
$gray-800: 											#404253 !default;
$gray-darker:                   #464E5F !default;
$gray-dark:                     lighten($gray-darker, 20%) !default;
$gray-light:                    #979797 !default;

// Primary colors
$primary-alt:                   #3775B7 !default;

// Secondary colors
$secondary:     									#e4f1ff !default; 
$secondary-hover:  								darken(#e4f1ff, 4%) !default;

// Info colors
//$info:         		 								#8950FC !default; // Bootstrap variable

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:                       #F3F6F9 !default;

// Tables
$table-color:                   $gray-light;
$table-head-color:              $gray-500;

// Forms
$input-solid-bg:                $gray-200;

// Modals
$modal-title-font-weight:       600 !default;