.uniforms-submit {
  position: relative;

  &--working & {
    &__icon {
      position: absolute;
      color: white;
      top: 8px;
      left: 6px;
      z-index: 1;
    }

    &__field {
      input {
        padding-left: 28px;
      }
    }
  }
}