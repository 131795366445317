
body {
  background: $body-bg;
}

.main-layout {
  &::before  {
    background: linear-gradient(88.13deg, $primary 0.71%, $primary-alt 98.72%);
    content: '';
    position: absolute;
    width: 100%;
    height: 320px;
    z-index: -1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.form-group.is-valid label {
  color: #404253 !important;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background: none !important;
  border-color: #E5EAEE !important;
}